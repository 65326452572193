import { Button, Col, Input, Modal, Radio, Row, Tooltip, message } from "antd";
import React, { useState } from "react";
import closeicon from "../../../Assets/advert_detail/closeicon.svg";
import caution_icon from "../../../Assets/advert_detail/caution.svg";
import crossIconUpdateAlert from "../../../Assets/advert_detail/whiteCrossIcon.svg";
import API_MANAGER from "../../../API";
import AlertState from "../AlertState";
import TransferCampaignDropdown from "../AdvertDetail/TransferCampaignDropdown";

function Reportpopup({ data, reportModal, setReportModal }) {
  const [alertState, setAlertState] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleCancelBtn = () => {
    setReportModal(false);
  };
  const handleOkBtn = async () => {
    setLoading(true);
    const params = {
      name: `${data?.candidateName}`,
      email: localStorage.getItem("userEmail"),
      contactedBy: "RECRUITER",
      summary: "This candidate has to be reported.",
      subject: "654a41bf312451e9f6866b26",
      isReported: true,
    };

    try {
      await API_MANAGER.postContactusData(params);
      setLoading(false);
      message.success("Candidate successfully reported.");
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong. Please try again.");
    }

    setReportModal(false);
  };
  return (
    <div>
      <AlertState
        state={alertState}
        message={alertMessage}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
      />
      <Modal
        centered
        width={392}
        title="Report Candidate"
        wrapClassName="report_modal_container"
        open={reportModal}
        // onOk={handleOk}
        closeIcon={<img src={closeicon} />}
        footer={[
          <Row>
            <Col span={24} align={"right"} className="justify_items_end">
              <Button
                key="submit"
                className="black_color_button"
                onClick={handleOkBtn}
              >
                Okay
              </Button>
              <Button
                key="submit"
                className="cancel_btn"
                onClick={handleCancelBtn}
              >
                Cancel
              </Button>
              ,
            </Col>
          </Row>,
        ]}
        onCancel={() => setReportModal(false)}
      >
        <Row>
          <Col span={24}>
            <h4>
              Are you sure you want to report <p>{data?.candidateName}?</p>
            </h4>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export const UpdatestatusPopup = ({
  updatePopup,
  setUpdatePopup,
  candidateSelectedData,
  onSubmit,
  name,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div>
      <Modal
        centered
        width={392}
        title="Update Status For:"
        wrapClassName="status_modal_container"
        open={updatePopup}
        // onOk={handleOk}
        closeIcon={<img src={closeicon} />}
        footer={
          <Row gutter={16} className="justify-end d-flex">
            <Col>
              <Button className="okBtn" onClick={() => setUpdatePopup(false)}>
                Cancel
              </Button>
            </Col>
            {!candidateSelectedData?.roleAccepted && (
              <Tooltip
                title={
                  (!candidateSelectedData &&
                    "You cannot update status until candidate accepts the invite.") ||
                  (!candidateSelectedData?.progress &&
                    candidateSelectedData?.isInvited === true &&
                    "You cannot update status until candidate accepts the invite.") ||
                  (candidateSelectedData?.progress?.[
                    candidateSelectedData?.progress?.length - 1
                  ]?.state === 2 &&
                    "You cannot update status until candidate accepts the shortlist.") ||
                  (candidateSelectedData?.progress?.[
                    candidateSelectedData?.progress?.length - 1
                  ]?.state === 4 &&
                    "You cannot accept role for the candidate.") ||
                  (!isChecked && "Please select next stage.")
                }
              >
                {candidateSelectedData?.progress?.[
                  candidateSelectedData?.progress?.length - 1
                ]?.state !== 5 ? (
                  <Col>
                    <Button
                      className="cancelBtn"
                      disabled={
                        !candidateSelectedData ||
                        (!candidateSelectedData?.progress &&
                          candidateSelectedData?.isInvited === true) ||
                        candidateSelectedData?.progress?.[
                          candidateSelectedData?.progress?.length - 1
                        ]?.state === 2 ||
                        candidateSelectedData?.progress?.[
                          candidateSelectedData?.progress?.length - 1
                        ]?.state === 4 ||
                        !isChecked
                      }
                      onClick={() => onSubmit()}
                    >
                      Update
                    </Button>
                  </Col>
                ) : null}
              </Tooltip>
            )}
          </Row>
        }
        onCancel={() => setUpdatePopup(false)}
      >
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <h4>
              {/* {console.log(candidateSelectedData?.profileId?.name, "CDD")} */}
              {candidateSelectedData?.candidateName
                ? candidateSelectedData?.candidateName
                : candidateSelectedData?.profileId?.name
                ? candidateSelectedData?.profileId?.name
                : name
                ? name
                : ""}
            </h4>
          </Col>

          <Col span={24}>
            <p style={{ display: "block" }}>Current Stage</p>
            <Radio className="radioBtn" defaultChecked={true} disabled>
              {candidateSelectedData?.roleAccepted === true
                ? "Role Accepted"
                : (!candidateSelectedData?.progress &&
                    candidateSelectedData?.isInvited === true) ||
                  !candidateSelectedData
                ? "Invited"
                : candidateSelectedData?.progress?.[
                    candidateSelectedData?.progress?.length - 1
                  ]?.state === 0
                ? "Applied"
                : candidateSelectedData?.progress?.[
                    candidateSelectedData?.progress?.length - 1
                  ]?.state === 1
                ? "Accepted"
                : candidateSelectedData?.progress?.[
                    candidateSelectedData?.progress?.length - 1
                  ]?.state === 2
                ? "Shortlist Offered"
                : candidateSelectedData?.progress?.[
                    candidateSelectedData?.progress?.length - 1
                  ]?.state === 3
                ? "Hard review"
                : candidateSelectedData?.progress?.[
                    candidateSelectedData?.progress?.length - 1
                  ]?.state === 4
                ? "Role Offered"
                : candidateSelectedData?.progress?.[
                    candidateSelectedData?.progress?.length - 1
                  ]?.state === 5
                ? "Role Accepted"
                : ""}
            </Radio>
          </Col>
          {!candidateSelectedData?.roleAccepted &&
            candidateSelectedData?.progress?.[
              candidateSelectedData?.progress?.length - 1
            ]?.state !== 5 && (
              <Col span={24}>
                <p style={{ display: "block" }} className="w-100">
                  Next Stage
                </p>
                <Radio
                  className="radioBtn"
                  onChange={(e) => setIsChecked(e?.target?.checked)}
                >
                  {(!candidateSelectedData?.progress &&
                    candidateSelectedData?.isInvited === true) ||
                  !candidateSelectedData
                    ? "Applied"
                    : candidateSelectedData?.progress?.[
                        candidateSelectedData?.progress?.length - 1
                      ]?.state === 0
                    ? "Shortlist Offered"
                    : candidateSelectedData?.progress?.[
                        candidateSelectedData?.progress?.length - 1
                      ]?.state === 1
                    ? "Hard review"
                    : candidateSelectedData?.progress?.[
                        candidateSelectedData?.progress?.length - 1
                      ]?.state === 2
                    ? "Hard review"
                    : candidateSelectedData?.progress?.[
                        candidateSelectedData?.progress?.length - 1
                      ]?.state === 3
                    ? "Role Offered"
                    : candidateSelectedData?.progress?.[
                        candidateSelectedData?.progress?.length - 1
                      ]?.state === 4
                    ? "Role Accept"
                    : ""}
                </Radio>
              </Col>
            )}
        </Row>
      </Modal>
    </div>
  );
};

export const TransferModal = ({
  transferModal,
  setTransferModal,
  candidateSelectedData,
  transferData,
  oldAdvertId,
  profileId,
  candidateName,
  setReloadState,
  setSearch,
  search,
  setadvertIds,
  setfilterDataAdvert,
}) => {
  const [newAdvertId, setNewAdvertId] = useState("");
  const [inviteIds, setInviteIds] = useState({});
  const [selectedAdverts, setSelectedAdverts] = useState([]);
  console.log(selectedAdverts, "selectedAdverts");

  console.log(transferData, "DATATEST");

  console.log(candidateSelectedData, "TESTSSS");
  const handleOkBtn = () => {
    // transferApplication();

    setTransferModal(false);
  };
  const handleCancelBtn = () => {
    setTransferModal(false);
  };

  const transferApplication = async () => {
    const data = {
      oldAdvertId: oldAdvertId,
      newAdvertId: newAdvertId,
      profileId: candidateSelectedData?.profileId?._id,
    };
    try {
      const response = await API_MANAGER.transferApplication(data);
      message.success({
        content: "Successfully transferred.",
      });
      if (response.data.isSuccess === true) {
        setReloadState(true);
      }
    } catch (error) {}
  };

  return (
    <Modal
      title={`Transfer ${candidateSelectedData?.profileId?.name} to:`}
      open={transferModal}
      onCancel={() => setTransferModal(false)}
      centered
      closable={true}
      footer={[
        <Row>
          <Col span={24} align={"right"} className="justify_items_end">
            <Button className="black_color_button" onClick={handleOkBtn}>
              Assign
            </Button>
            <Button
              key="submit"
              className="cancel_btn"
              onClick={() => setTransferModal(false)}
            >
              Cancel
            </Button>
            ,
          </Col>
        </Row>,
      ]}
    >
      <Input
        className="search-input mb-20"
        size="large"
        placeholder="Select or search campaign"
        value={search}
        onChange={(e) => setSearch(e?.target?.value)}
        prefix={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M19.8947 19.0002L15.5517 14.6572M15.5517 14.6572C16.2946 13.9143 16.8839 13.0324 17.286 12.0618C17.688 11.0911 17.8949 10.0508 17.8949 9.00021C17.8949 7.9496 17.688 6.90929 17.286 5.93866C16.8839 4.96803 16.2946 4.08609 15.5517 3.34321C14.8088 2.60032 13.9269 2.01103 12.9563 1.60898C11.9857 1.20693 10.9453 1 9.89474 1C8.84414 1 7.80382 1.20693 6.83319 1.60898C5.86256 2.01103 4.98062 2.60032 4.23774 3.34321C2.73741 4.84354 1.89453 6.87842 1.89453 9.00021C1.89453 11.122 2.73741 13.1569 4.23774 14.6572C5.73807 16.1575 7.77295 17.0004 9.89474 17.0004C12.0165 17.0004 14.0514 16.1575 15.5517 14.6572Z"
              stroke="#1638B1"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
      />
      <div className="campaignListContainer">
        {transferData?.map((item, index) => (
          <TransferCampaignDropdown
            setInviteIds={setInviteIds}
            inviteIds={inviteIds}
            item={item}
            key={index}
            setNewAdvertId={setNewAdvertId}
            setSelectedAdverts={setSelectedAdverts}
            setfilterDataAdvert={setfilterDataAdvert}
            selectedAdverts={selectedAdverts}
            setadvertIds={setadvertIds}
          />
        ))}
      </div>
    </Modal>
  );
};

export const UpdateAlert = ({ updateAlert, setUpdateAlert }) => {
  return (
    <Modal
      width={500}
      title={false}
      wrapClassName="update_alert_modal_container"
      open={updateAlert}
      // onOk={handleOk}
      closeIcon={
        <img
          style={{ position: "relative", top: "10px" }}
          src={crossIconUpdateAlert}
        />
      }
      footer={false}
      onCancel={() => setUpdateAlert(false)}
    >
      <Row align={"middle"}>
        <Col span={2}>
          <img src={caution_icon} />
        </Col>
        <Col span={22}>
          <span>
            You can’t change the status of candidates who are at different
            stages of their application process.
          </span>
        </Col>
      </Row>
    </Modal>
  );
};
export const DeleteUserPopup = ({ isModalOpen, setisModalOpen }) => {
  const handleCancelBtn = () => {
    setisModalOpen(false);
  };
  const handleOkBtn = () => {
    message.success({
      content: "Candidate deleted successfully",
    });
    setisModalOpen(false);
  };
  return (
    <div>
      <Modal
        centered
        width={392}
        title="Report Candidate?"
        wrapClassName="report_modal_container"
        open={isModalOpen}
        // onOk={handleOk}
        closeIcon={<img src={closeicon} />}
        footer={
          <Row gutter={16} className="justify-end d-flex">
            <Col>
              <Button className="okBtn" onClick={handleOkBtn}>
                Yes
              </Button>
            </Col>
            <Col>
              <Button className="cancelBtn" onClick={handleCancelBtn}>
                Cancel
              </Button>
            </Col>
          </Row>
        }
        onCancel={() => setisModalOpen(false)}
      >
        <Row>
          <Col span={24}>
            <h4>Are you sure you want to delete this candidate?</h4>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
export default Reportpopup;

export const StatusModal = ({
  setOpen,
  open,
  candidateSelectedData,
  onSubmit,
  selectedRowData,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div>
      <Modal
        centered
        width={392}
        title="Update Status For:"
        wrapClassName="status_modal_container"
        open={open}
        // onOk={handleOk}
        closeIcon={<img src={closeicon} />}
        footer={
          <Row gutter={16} className="justify-end d-flex">
            <Col>
              <Button className="okBtn" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </Col>
            {!candidateSelectedData?.roleAccepted && (
              <Col>
                <Tooltip
                  title={
                    (!candidateSelectedData?.progress &&
                      candidateSelectedData?.isInvited === true &&
                      "You cannot update status until candidate accepts the invite.") ||
                    (candidateSelectedData?.progress?.[
                      candidateSelectedData?.progress?.length - 1
                    ]?.state === 2 &&
                      "You cannot update status until candidate accepts the shortlist.") ||
                    (candidateSelectedData?.progress?.[
                      candidateSelectedData?.progress?.length - 1
                    ]?.state === 4 &&
                      "You cannot accept role for the candidate.") ||
                    (!isChecked && "Please select next stage.")
                  }
                >
                  <Col>
                    <Button
                      className="cancelBtn"
                      disabled={
                        (!candidateSelectedData?.progress &&
                          candidateSelectedData?.isInvited === true) ||
                        candidateSelectedData?.progress?.[
                          candidateSelectedData?.progress?.length - 1
                        ]?.state === 2 ||
                        candidateSelectedData?.progress?.[
                          candidateSelectedData?.progress?.length - 1
                        ]?.state === 4 ||
                        !isChecked
                      }
                      onClick={() => onSubmit()}
                    >
                      Update
                    </Button>
                  </Col>
                </Tooltip>
              </Col>
            )}
          </Row>
        }
        onCancel={() => setOpen(false)}
      >
        {candidateSelectedData && (
          <>
            {selectedRowData &&
              selectedRowData?.map((data, index) => {
                return (
                  <>
                    <span style={{ fontWeight: 500 }}>
                      {data?.profileId?.name}
                    </span>
                    {index !== selectedRowData?.length - 1 ? (
                      <span>, </span>
                    ) : null}
                  </>
                );
              })}
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <h4>{candidateSelectedData?.name}</h4>
              </Col>

              <Col span={24}>
                <p style={{ display: "block" }}>Current Stage</p>
                <Radio className="radioBtn" defaultChecked={true} disabled>
                  {candidateSelectedData?.roleAccepted
                    ? "Role accepted"
                    : !candidateSelectedData?.progress &&
                      candidateSelectedData?.isInvited === true
                    ? "Invited"
                    : candidateSelectedData?.progress?.[
                        candidateSelectedData?.progress?.length - 1
                      ]?.state === 0
                    ? "Applied"
                    : candidateSelectedData?.progress?.[
                        candidateSelectedData?.progress?.length - 1
                      ]?.state === 1
                    ? "Accepted"
                    : candidateSelectedData?.progress?.[
                        candidateSelectedData?.progress?.length - 1
                      ]?.state === 2
                    ? "Shortlist Offered"
                    : candidateSelectedData?.progress?.[
                        candidateSelectedData?.progress?.length - 1
                      ]?.state === 3
                    ? "Hard review"
                    : candidateSelectedData?.progress?.[
                        candidateSelectedData?.progress?.length - 1
                      ]?.state === 4
                    ? "Role Offered"
                    : ""}
                </Radio>
              </Col>
              {!candidateSelectedData?.roleAccepted && (
                <Col span={24}>
                  <p style={{ display: "block" }} className="w-100">
                    Next Stage
                  </p>
                  <Radio
                    className="radioBtn"
                    onChange={(e) => setIsChecked(e?.target?.checked)}
                  >
                    {!candidateSelectedData?.progress &&
                    candidateSelectedData?.isInvited === true
                      ? "Applied"
                      : candidateSelectedData?.progress?.[
                          candidateSelectedData?.progress?.length - 1
                        ]?.state === 0
                      ? "Shortlist Offered"
                      : candidateSelectedData?.progress?.[
                          candidateSelectedData?.progress?.length - 1
                        ]?.state === 1
                      ? "Hard review"
                      : candidateSelectedData?.progress?.[
                          candidateSelectedData?.progress?.length - 1
                        ]?.state === 2
                      ? "Hard review"
                      : candidateSelectedData?.progress?.[
                          candidateSelectedData?.progress?.length - 1
                        ]?.state === 3
                      ? "Role Offered"
                      : candidateSelectedData?.progress?.[
                          candidateSelectedData?.progress?.length - 1
                        ]?.state === 4
                      ? "Role Accept"
                      : ""}
                  </Radio>
                </Col>
              )}
            </Row>
          </>
        )}
      </Modal>
    </div>
  );
};
