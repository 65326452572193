import { Row, Col, Checkbox } from "antd";
import React, { useState } from "react";
import CloseArrow from "../../../Assets/closeArrow.svg";
import OpenArrow from "../../../Assets/openArrow.svg";
import ListArrow from "../../../Assets/listArrow.svg";
import RightIcon from "../../../Assets/rightIcon.svg";
function MobilzationTransferCampaignDropdown({
  item,
  setInviteIds,
  inviteIds,
  setNewAdvertId,
  setSelectedAdverts,
  selectedAdverts,
  setfilterDataAdvert,
  setadvertIds,
  handleOkBtn,
  setids,
}) {
  const [open, setOpen] = useState(true);
  console.log(item, "TESTADDD");
  const handleClick = (id) => {
    if (selectedAdverts?.includes(id)) {
      const tempData = selectedAdverts?.filter((e) => e !== id);
      setSelectedAdverts(tempData);
      setfilterDataAdvert(tempData);
      setInviteIds({ ...inviteIds, [item?._id]: tempData });
      setNewAdvertId(tempData);
      setadvertIds(tempData);
      setids(tempData);
    } else {
      const tempData = [...selectedAdverts, id];
      setSelectedAdverts(tempData);
      setfilterDataAdvert(tempData);
      setInviteIds({ ...inviteIds, [item?._id]: tempData });
      setNewAdvertId(tempData);
      setadvertIds(tempData);
      setids(tempData);
    }
    handleOkBtn();
  };

  const handleCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    const advertIds = item?.adverts?.map((adv) => adv._id) || [];

    if (isChecked) {
      // Select all adverts
      const newSelected = [...new Set([...selectedAdverts, ...advertIds])];
      setSelectedAdverts(newSelected);
      setfilterDataAdvert(newSelected);
      setInviteIds({ ...inviteIds, [item?._id]: newSelected });
      setNewAdvertId(newSelected);
      setadvertIds(newSelected);
      setids(newSelected);
    } else {
      // Deselect all adverts
      const newSelected = selectedAdverts.filter(
        (id) => !advertIds.includes(id)
      );
      setSelectedAdverts(newSelected);
      setfilterDataAdvert(newSelected);
      setInviteIds({ ...inviteIds, [item?._id]: newSelected });
      setNewAdvertId(newSelected);
      setadvertIds(newSelected);
      setids(newSelected);
    }
    handleOkBtn();
  };

  return (
    <div className="inviteCampaignDropDown">
      <Row
        align={"middle"}
        gutter={12}
        justify={"space-between"}
        className={`cursor-pointer ${
          //   selectedAdverts?.length > 0
          // ? "activeCampaignContainer"
          "campaignContainer"
        }`}
        onClick={() => setOpen(!open)}
      >
        <Col style={{ width: "calc(100% - 36px)" }}>
          <Row align={"middle"} gutter={12} justify="space-between">
            <Col>
              {open ? (
                <img src={OpenArrow} alt="open" />
              ) : (
                <img src={CloseArrow} alt="close" />
              )}
            </Col>
            {console.log(item, "TESII")}
            <Col flex="auto" className="name">
              {item?.name}
            </Col>
            <Col>
              <Checkbox onChange={(e) => handleCheckboxChange(e, item)} />
            </Col>
          </Row>
        </Col>
        {/* {selectedAdverts?.length > 0 && (
          <Col>
            <img src={RightIcon} alt="img" />
          </Col>
        )} */}
      </Row>
      {open && (
        <div className="advertContainer">
          {item?.adverts?.map((data, index) => (
            <Row
              align={"middle"}
              gutter={8}
              justify={"space-between"}
              className={`cursor-pointer ${
                selectedAdverts?.includes(data?._id)
                  ? "activeAdvertItem"
                  : "advertItem"
              } `}
              onClick={() => {
                handleClick(data?._id);
              }}
            >
              <Col style={{ width: "calc(100% - 36px)" }}>
                <Row align={"middle"} gutter={8}>
                  <Col>
                    <img src={ListArrow} alt="opwn" />
                  </Col>
                  <Col style={{ width: "calc(100% - 30px)" }} className="name">
                    {data?.jobTitle?.name}
                  </Col>
                </Row>
              </Col>
              {selectedAdverts?.includes(data?._id) && (
                <Col>
                  <img src={RightIcon} alt="img" />
                </Col>
              )}
            </Row>
          ))}
        </div>
      )}
    </div>
  );
}

export default MobilzationTransferCampaignDropdown;
