import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Card, Popover } from "antd";
import {
  InfoCircleOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";

const UserProfileCard = ({ record }) => {
  console.log(record, "YT");
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div style={{ display: "inline-block" }}>
      <Popover
        overlayClassName="custom-popover"
        content={
          <Card
            style={{
              width: 250,
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              borderRadius: 8,
            }}
            bodyStyle={{ padding: 15 }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Avatar
                size={50}
                src="https://images.freeimages.com/image/thumbs/374/instabutton-png-design-5690390.png"
              />
              <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <DeleteOutlined
                  style={{ color: "red", fontSize: "18px", cursor: "pointer" }}
                />
                <EyeInvisibleOutlined
                  style={{ color: "#666", fontSize: "18px", cursor: "pointer" }}
                />
                <AppstoreOutlined
                  style={{
                    color: "#3B82F6",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/advert-detail/${record.advertIds}`)}
                />
              </div>
            </div>

            <div style={{ marginTop: 10 }}>
              {/* <h3 style={{ margin: 0 }}>{record.advertIds}</h3> */}
              <h3 style={{ margin: 0 }}>{record.name}</h3>
              <p style={{ margin: 0, color: "#666" }}>{record.email}</p>
              <p style={{ margin: 0, color: "#666" }}>{record.jobTitle}</p>
              <p style={{ margin: 0, color: "#666" }}>{record.startDate}</p>
              <p style={{ margin: 0, color: "#666" }}>{record.endDate}</p>
            </div>
          </Card>
        }
        trigger="click"
        visible={isVisible}
        onVisibleChange={(visible) => setIsVisible(visible)}
        placement="rightTop"
      >
        <InfoCircleOutlined
          style={{ color: "#3B82F6", fontSize: "16px", cursor: "pointer" }}
          onClick={() => setIsVisible(!isVisible)}
        />
      </Popover>
    </div>
  );
};

export default UserProfileCard;
