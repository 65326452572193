import { useState, useEffect } from "react";
import { Popover, Button, Checkbox, Divider, Input } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

const ShowHideColumnsPopover = ({
  columnsvisible,
  toggleVisibility,
  setmobilzationColumn,
  sethidecolumn,
}) => {
  const validColumns = Array.isArray(setmobilzationColumn)
    ? setmobilzationColumn
    : [];

  const [visible, setVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    setSelectedColumns([]);
  }, [setmobilzationColumn]);

  const handleCheckboxToggle = (dataIndex) => {
    const updatedSelection = selectedColumns.includes(dataIndex)
      ? selectedColumns.filter((col) => col !== dataIndex)
      : [...selectedColumns, dataIndex];

    setSelectedColumns(updatedSelection);
    sethidecolumn(updatedSelection);
  };

  const filteredColumns = validColumns.filter((col) =>
    col.dataIndex.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const content = (
    <div
      style={{ width: 250, padding: 10, background: "#fff", borderRadius: 8 }}
    >
      <h4
        style={{
          marginBottom: 10,
          fontSize: 14,
          fontWeight: 600,
          color: "black",
        }}
      >
        SHOW/HIDE COLUMNS
      </h4>
      <Input
        placeholder="Search"
        style={{ marginBottom: 8 }}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div
        style={{
          maxHeight: "200px", // ✅ Restrict height
          overflowY: "auto", // ✅ Enable scrolling
          paddingRight: "5px",
        }}
      >
        {filteredColumns.length > 0 ? (
          filteredColumns.map((col, idx) => (
            <div key={col.dataIndex}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "5px 0",
                }}
              >
                <Checkbox
                  checked={selectedColumns.includes(col.dataIndex)}
                  onChange={() => handleCheckboxToggle(col.dataIndex)}
                >
                  {col.dataIndex}
                </Checkbox>
              </div>
              {idx !== filteredColumns.length - 1 && (
                <Divider style={{ margin: "8px 0" }} />
              )}
            </div>
          ))
        ) : (
          <div style={{ textAlign: "center", color: "#999" }}>
            No data found
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      placement="bottomLeft"
      open={visible}
      onOpenChange={setVisible}
      overlayInnerStyle={{ backgroundColor: "#fff", padding: "0px" }}
    >
      <Button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          background: "#F8FAFC",
          border: "1px solid #E2E8F0",
          borderRadius: "8px",
          padding: "20px 12px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#64748B",
          cursor: "pointer",
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "28px",
            height: "28px",
            background: "#EEF4FF",
            borderRadius: "6px",
          }}
        >
          <EyeInvisibleOutlined
            style={{ fontSize: "16px", color: "#1638B1" }}
          />
        </div>
        Show/Hide
      </Button>
    </Popover>
  );
};

export default ShowHideColumnsPopover;
