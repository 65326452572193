import { useEffect, useRef, useState } from "react";
import { Button, Popover, Input, message } from "antd";
import { FilterOutlined, MenuOutlined } from "@ant-design/icons";
import API_MANAGER from "../../../API";
import MobilzationTransferCampaignDropdown from "./MobilzationTransferCampaignDropdown";

export const TransferPopover = ({
  candidateSelectedData,
  transferData,
  oldAdvertId,
  setReloadState,
  setSearch,
  search,
  setadvertIds,
  advertIds,
  setfilterDataUser,
  reloadState,
  searchText,
  sortValue,
  setLoadingTable,

  setmobilzationColumn,

  setfilterDataAdvert,
}) => {
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [newAdvertId, setNewAdvertId] = useState("");

  const [inviteIds, setInviteIds] = useState({});
  const [selectedAdverts, setSelectedAdverts] = useState([]);
  const [ids, setids] = useState([]);
  const advertIdsfinal = transferData.flatMap(
    (item) => item.adverts?.map((ad) => ad._id) || []
  );
  const lastAppliedRef = useRef({ searchText, sortValue, advertIdsfinal });

  console.log(searchText, sortValue, "TEST+_+_+");

  // Extract all advert _ids

  const handleOkBtn = () => {
    console.log(ids, "logids");
    // transferApplication();
  };

  const applyInitial = async () => {
    setLoadingTable(true);
    const storedTrigger = localStorage.getItem("applyTrigger");

    // Prevent unnecessary API calls if values haven't changed
    if (
      lastAppliedRef.current.searchText === searchText &&
      lastAppliedRef.current.sortValue === sortValue &&
      JSON.stringify(lastAppliedRef.current.advertIdsfinal) ===
        JSON.stringify(advertIdsfinal)
    ) {
      setLoadingTable(false);
      return;
    }

    if (
      (!storedTrigger || storedTrigger !== "true") &&
      !searchText &&
      !sortValue
    )
      return;
    if (!advertIdsfinal.length) return;

    try {
      const response = await API_MANAGER.postGateData({
        adverts: advertIdsfinal,
        searchText: searchText,
        sortValue: sortValue,
      });

      if (response.data.isSuccess) {
        setmobilzationColumn(response?.data?.data?.column);
        setfilterDataUser(response?.data?.data?.users);

        // Update the ref with the latest values
        lastAppliedRef.current = { searchText, sortValue, advertIdsfinal };
      }
    } catch (error) {
      message.error("Advert Application Failed.");
    }

    localStorage.removeItem("applyTrigger"); // Reset the flag after execution
  };

  // Trigger API when advertIdsfinal, searchText, or sortValue changes
  useEffect(() => {
    applyInitial();
  }, [advertIdsfinal, searchText, sortValue]);

  console.log(searchText, sortValue, "____+_+__+__+___");

  useEffect(() => {
    const transferApplication = async () => {
      if (!ids || ids.length === 0) return;

      const data = {
        adverts: ids,
        searchText: searchText,
        sortValue: sortValue,
      };
      console.log(data, "TEST IDT");

      try {
        const response = await API_MANAGER.postGateData(data);
        console.log(response, "&&&");
        setmobilzationColumn(response?.data?.data?.column);
        setfilterDataUser(response?.data?.data?.users);

        console.log(response?.data?.data?.users, "CHECKRESP");

        if (response.data.isSuccess) {
          message.success("Successfully Filtered.");
        }
      } catch (error) {
        message.error("Filter Operation Failed.");
      }
    };

    transferApplication();
  }, [ids]);

  // const transferApplication = async () => {
  //   console.log(ids, ")))");
  //   const data = {
  //     adverts: ids,
  //   };
  //   console.log(data, "TEST IDT");
  //   try {
  //     const response = await API_MANAGER.postGateData(data);
  //     if (response.data.isSuccess === true) {
  //       message.success("Successfully Filtered.");
  //       // setReloadState(true);
  //     }
  //   } catch (error) {
  //     message.error("Filter Operation  Failed.");
  //   }
  // };

  const contentFilter = (
    <div
      style={{
        width: 260,
        background: "#fff",
        borderRadius: 8,
        padding: 15,
        boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
      }}
    >
      <h4
        style={{
          color: "#1E1E1E",
          // fontFamily: "Inter",
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
          textTransform: "uppercase",
          alignSelf: "stretch",
          marginBottom: 8,
        }}
      >
        FILTERS
      </h4>

      {/* <Input
        className="search-input mb-20"
        size="large"
        placeholder="Select or search campaign"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      /> */}

      <h4
        style={{
          color: "#1E1E1E",
          marginTop: 8,
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
          textTransform: "uppercase",
          alignSelf: "stretch",
          marginBottom: 8,
          position: "relative",
          display: "inline-block",
        }}
      >
        campaigns & Adverts
      </h4>
      <div className="campaignListContainer">
        {transferData?.map((item, index) => (
          <MobilzationTransferCampaignDropdown
            setInviteIds={setInviteIds}
            inviteIds={inviteIds}
            item={item}
            key={index}
            setNewAdvertId={setNewAdvertId}
            setSelectedAdverts={setSelectedAdverts}
            setfilterDataAdvert={setfilterDataAdvert}
            selectedAdverts={selectedAdverts}
            setadvertIds={setadvertIds}
            handleOkBtn={handleOkBtn}
            advertIds={advertIds}
            setids={setids}
          />
        ))}
      </div>
      {/* <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
      >
        <Button type="primary" onClick={handleOkBtn}>
          Assign
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          onClick={() => setVisibleFilter(false)}
        >
          Cancel
        </Button>
      </div> */}
    </div>
  );

  return (
    <Popover
      content={contentFilter}
      trigger="click"
      placement="bottomLeft"
      open={visibleFilter}
      onOpenChange={(visible) => setVisibleFilter(visible)}
      overlayStyle={{
        border: "none",
        boxShadow: "none",
      }}
      overlayInnerStyle={{
        backgroundColor: "#fff",
        padding: "0px",
      }}
    >
      <Button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          background: "#F8FAFC",
          border: "1px solid #E2E8F0",
          borderRadius: "8px",
          padding: "20px 12px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#64748B",
          cursor: "pointer",
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "28px",
            height: "28px",
            background: "#EEF4FF",
            borderRadius: "6px",
          }}
        >
          <MenuOutlined style={{ fontSize: "16px", color: "#1638B1" }} />
        </div>
        Filter By
      </Button>
    </Popover>
  );
};
