import React, { useState, useEffect, useCallback } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { useNavigate, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Space,
  Typography,
  Popover,
  Checkbox,
  Divider,
  Radio,
  Modal,
  Select,
  Row,
  Col,
  Calendar,
  Avatar,
  message,
  Menu,
  InputNumber,
  DatePicker,
} from "antd";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  SearchOutlined,
  EyeInvisibleOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteFilled,
  AppstoreAddOutlined,
  MenuOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import { Table, Badge, Tag, Dropdown } from "antd";
import {
  EyeOutlined,
  LeftOutlined,
  DownOutlined,
  InfoCircleOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  PercentageOutlined,
  CalendarOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import API_MANAGER from "../../API";
import { TransferModal } from "../../components/common/Mobilization/MobilizationTransferModal";
import AssignIcon from "../../Assets/assign.svg";
import { TransferPopover } from "../../components/common/Mobilization/MobilizationFilter";
import LoaderGif from "../../Assets/loader.gif";

import ShowHideColumnsPopover from "../../components/common/Mobilization/ShowHidePopover";

import YesIcon from "../../Assets/greentick.svg";
import NoIcon from "../../Assets/stop.svg";

import NAIcon from "../../Assets/warning.svg";
import UserProfileCard from "../../components/common/Mobilization/ProfileCard";
import NoElement from "../../../src/Assets/campagin/no-campaign-image.svg";
import CustomPagination from "../../components/common/CustomPagination";
// Status Icons
const statusIcons = {
  success: <CheckCircleFilled style={{ color: "#10B981", fontSize: 18 }} />,
  warning: (
    <ExclamationCircleFilled style={{ color: "#FACC15", fontSize: 18 }} />
  ),
  error: <CloseCircleFilled style={{ color: "#EF4444", fontSize: 18 }} />,
};

// Function to handle column drag and drop

const options = [
  { label: "Calendar", icon: <CalendarOutlined /> },
  { label: "Pick List", icon: <OrderedListOutlined /> },
  {
    label: "Yes/No",
    icon: <PercentageOutlined style={{ visibility: "hidden" }} />,
  },
];

const columnData = [
  {
    category: "Personal Details",
    columns: [
      { key: "email", label: "Email", checked: true },
      { key: "photo", label: "Photo", checked: true },
      { key: "name", label: "Name", checked: true },
      { key: "dob", label: "DOB", checked: true },
      { key: "gender", label: "Gender", checked: true },
      { key: "ethnicity", label: "Ethnicity", checked: true },
      { key: "phone", label: "Phone Number", checked: true },
      { key: "next_of_kin", label: "Next-of-Kin", checked: true },
    ],
  },
  {
    category: "Location",
    columns: [{ key: "address", label: "Address", checked: true }],
  },
  {
    category: "Other Details",
    columns: [
      { key: "lsl", label: "LSL", checked: true },
      { key: "ppe", label: "PPE", checked: true },
      {
        key: "travel",
        label: "Travel - Preferred Airline and Frequent Flyer Number",
        checked: true,
      },
      { key: "police", label: "Police Clearance", checked: true },
      { key: "medical", label: "Medical", checked: true },
    ],
  },
  {
    category: "Recruitment",
    columns: [
      { key: "campaign_name", label: "Campaign Name", checked: true },
      { key: "project_code", label: "Project Code", checked: true },
      {
        key: "advert_name",
        label: "Advert Name (e.g., Job Title)",
        checked: true,
      },
      { key: "roster", label: "Roster", checked: true },
      { key: "employment_type", label: "Employment Type", checked: true },
      {
        key: "hard_compliance",
        label: "Hard Compliance Requirements",
        checked: true,
      },
      {
        key: "soft_compliance",
        label: "Soft Compliance Requirements",
        checked: true,
      },
      { key: "client_approval", label: "Client Approval", checked: true },
    ],
  },
  {
    category: "Manual Entries",
    columns: [
      {
        key: "manual_gate",
        label: "Manually Entered/Created Gate",
        checked: true,
      },
    ],
  },
];

// Sorting Function for Text Fields

// Flatten the nested array

// Table Columns

function MobilisationManagement() {
  const [loadingTable, setLoadingTable] = useState(false);
  const [activeTab, setActiveTab] = useState("manual"); // Track active tab

  const [name, setName] = useState("");

  const [search, setSearch] = useState(null);

  const [visibleFilter, setVisibleFilter] = useState(false);
  const [columnsvisible, setcolumnsvisible] = useState(columnData);
  const [visibleSort, setvisibleSort] = useState();
  const [sortValue, setSortValue] = useState("recentlyAdded");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [filterDataAdvert, setfilterDataAdvert] = useState();
  const [columngatedata, setcolumngatedata] = useState();
  const [gateInfo, setgateInfo] = useState();
  console.log(gateInfo?.[0]?.gates, "TESTGATEINFO");

  const [numValues, setNumValues] = useState(1);
  const [values, setValues] = useState([""]);

  const handleChangePickLists = (index, newValue) => {
    const updatedValues = [...values];
    updatedValues[index] = newValue;
    setValues(updatedValues);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddGateModalOpen, setIsAddGateModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [PopoverVisiblePickList, setPopoverVisiblePickList] = useState(false);
  const [YesNoValue, setYesNoValue] = useState();

  // console.log(PopoverVisiblePickList, "setPopoverVisiblePickList");

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState("false");
  const [dropdown, setdropdown] = useState(false);
  console.log(popoverVisible, "TESTPOP");
  const { id } = useParams();
  const userType = localStorage.getItem("user");
  const [selectedType, setSelectedType] = useState("Calendar");
  console.log(selectedType, "TEST CEL");

  const tomorrow = dayjs().add(1, "day");

  const [transferModal, setTransferModal] = useState(false);
  const [candidateSelectedData, setCandidateSelectedData] = useState({});
  const [oldAdvertId, setOldAdvertId] = useState("");
  const [sortType, setSortType] = useState("-createdAt");
  // const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [profileId, setProfileId] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [reloadState, setReloadState] = useState(false);
  const [transferData, setTransferData] = useState([]);
  const [mobilzationColumn, setmobilzationColumn] = useState();

  const [hidecolumn, sethidecolumn] = useState([]);
  console.log(hidecolumn, "mobilzationhidecolumnColumn");

  const [advertSearch, setAdvertSearch] = useState("");
  const [advertIds, setadvertIds] = useState([]);

  const [filterDataUser, setfilterDataUser] = useState();
  console.log(filterDataUser, "KL");

  const [data, setData] = useState([]);
  const pagination = data.length;
  console.log(pagination, "TESTLENll");
  // console.log(data, "LEN CHECK");
  const firstDetail = data?.length > 0 ? data[0] : null;
  console.log(data, "+++++++++++++++++=");

  useEffect(() => {
    if (!filterDataUser) return;

    const transformedData = filterDataUser.map((entry, index) => {
      console.log(entry, "TESTENRTY");
      const userInfo =
        Array.isArray(entry) && entry.length > 0 ? entry[0] : null;

      const gates = entry.slice(1);

      console.log(userInfo, "TEST USER INFO");

      // Extract gate information dynamically
      const gateData = gates?.reduce((acc, gate) => {
        acc[gate?.gate] = gate?.dataIndex !== null ? gate?.dataIndex : "N/A";
        return acc;
      }, {});
      console.log(gateData, "TESTGATE DATA");
      setgateInfo(entry);

      const mappedData = mobilzationColumn.reduce((acc, { dataIndex }) => {
        let value = gateData[dataIndex]; // Get actual value from gateData

        // Convert boolean values to "Yes" or "No"
        if (typeof value === "boolean") {
          value = value ? "Yes" : "No";
        } else if (value === "pending") {
          value = "N/A"; // Default for missing values
        } else {
          value = value || <p>&#8709;</p>;
        }

        acc[dataIndex] = value; // Assign dynamically
        return acc;
      }, {});
      console.log(gateData, "GG");

      const formattedDataGate = mobilzationColumn?.reduce(
        (acc, { dataIndex }) => {
          acc[dataIndex] = ["photo", "name"].includes(dataIndex)
            ? "N/A"
            : false;
          return acc;
        },
        {}
      );
      setcolumngatedata(formattedDataGate);

      console.log(userInfo?.userName, "PPP");
      return {
        key: (index + 1).toString(),

        advertIds: userInfo.advertId,
        startDate: userInfo.startDate,
        endDate: userInfo.endDate,
        jobTitle: userInfo.jobTitle,
        userName: userInfo?.userName,
        email: userInfo.email ? true : false,

        ...mappedData,
      };
    });

    setData(transformedData);
  }, [filterDataUser]);

  const [selectedGates, setSelectedGates] = useState([]);
  console.log(selectedGates, "selectedGates");

  useEffect(() => {
    localStorage.setItem("applyTrigger", "true");
  }, []);

  const handleCheckboxChange = (value) => {
    setSelectedGates((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
  };

  const getCandidateDetails = async () => {
    setLoading(true);
    const param = {
      page: currentPage,
      limit: 10,
      sort: sortType,
      search: search,
    };
    try {
      const response = await API_MANAGER.getAdvertCandidatesDetail(id, param);
      console.log(response, "TEST RESPONSE");
      setReloadState(false);
      // setCandidatesDetails(response?.data?.data);
      setOldAdvertId(response?.data?.data?.result[0]?.advertId?._id);
      setProfileId(response?.data?.data?.result[0]?.profileId?._id);
      setCandidateName(response?.data?.data?.result[0]?.candidateName);
    } catch (error) {
      // message.error("Something went wrong. Please try again.");
    }
    setLoading(false);
  };

  const getAdvertData = async (id) => {
    setLoading(true);
    // let params = {
    //   page: 1,
    //   limit: 100,
    //   sort: "-createdAt",
    // };
    let params = {
      profileId:
        candidateSelectedData?.profileId?._id || "6683935e3530f1defe9e4301",
      sort: "-createdAt",
      limit: 20000,
      page: 1,
    };
    if (advertSearch) {
      params = { ...params, ["search"]: advertSearch };
    }
    if (userType !== "Associate") {
      try {
        const response = await API_MANAGER.getSingleUserCampaignDetailsAll(
          params
        );
        setTransferData(response?.data?.data);
      } catch (error) {
        message.error("Something went wrong. Please try again.");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    getAdvertData();
  }, [advertSearch]);

  useEffect(() => {
    getCandidateDetails();
  }, [search, sortType, currentPage, id, reloadState == true]);

  const onClose = () => {
    setPopoverVisiblePickList(false);
  };

  const [selectedDate, setSelectedDate] = useState(null);

  // Handle click event
  const handleDateClick = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate); // Store selected date in state
  };

  // const [typeMannual, settypeMannual] = useState();

  const [searchText, setSearchText] = useState("");
  console.log(searchText, "IOP");

  const handleSearchChange = (e) => {
    console.log(e, "EE");
    setSearchText(e.target.value);
    console.log("Search value:", e.target.value);
  };

  const handleButtonClick = (label) => {
    setSelectedType(label);
    if (label === "Calendar") {
      setPopoverVisible(!popoverVisible);
    }
    if (label === "Pick List") {
      setPopoverVisiblePickList(!PopoverVisiblePickList);
      setDropdownVisible(true);
    }
    if (label === "Yes/No") {
      setdropdown(!dropdown);
    }
  };

  // Render date cells with popover
  const dateCellRender = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
  };

  const openAddGateModal = () => {
    setIsAddGateModalOpen(true);
  };

  const closeAddGateModal = () => {
    // handelSaveGate();
    setIsAddGateModalOpen(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    console.log("Deleted!"); // Perform delete action here
  };

  const handleSortChange = (e) => {
    setSortValue(e.target.value);
  };

  const [checkedKeys, setCheckedKeys] = useState([]);

  const contentSort = (
    <div
      style={{ width: 180, background: "#fff", padding: 12, borderRadius: 8 }}
    >
      <h4
        style={{
          fontSize: 14,
          fontWeight: 600,
          marginBottom: 10,
          color: "black",
        }}
      >
        SORT LIST
      </h4>
      <Radio.Group
        onChange={handleSortChange}
        value={sortValue}
        style={{ display: "flex", flexDirection: "column", gap: 6 }}
      >
        <Radio value="recentlyAdded" style={{ fontSize: 13 }}>
          Recently Added
        </Radio>
        <Radio value="alphabetical" style={{ fontSize: 13 }}>
          Alphabetical
        </Radio>
        {/* <Radio value="date_range" style={{ fontSize: 13 }}>
          Date Range
        </Radio> */}
      </Radio.Group>
    </div>
  );

  const handleCheck = (key) => {
    setCheckedKeys((prev) =>
      prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]
    );
  };
  const renderTree = (items, level = 0) =>
    items.map((item) => (
      <div
        key={item.key}
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: level * 15, // Controls indentation
          paddingBottom: 5,
        }}
      >
        {item.children ? (
          <DownOutlined style={{ fontSize: 12, marginRight: 5 }} />
        ) : (
          <span style={{ width: 17 }} /> // Keeps alignment consistent for all rows
        )}
        <Checkbox
          checked={checkedKeys.includes(item.key)}
          onChange={() => handleCheck(item.key)}
          style={{ fontSize: 14 }}
        >
          {item.label}
        </Checkbox>
      </div>
    ));

  const toggleVisibility = (key) => {
    setcolumnsvisible((prev) =>
      prev.map((section) => ({
        ...section,
        columnsvisible: section.columnsvisible.map((col) =>
          col.key === key ? { ...col, checked: !col.checked } : col
        ),
      }))
    );
  };

  //API

  const handleAddAutoPopulatedGates = async () => {
    try {
      const data = {
        advertId: advertIds,
        category: "personal details",
        name: selectedGates,
      };

      const response = await API_MANAGER.addAutoPopulatedGates(data);
      console.log(response, "TEST RESPONSE");

      if (response?.status === 201) {
        message.success("Gates added successfully.");
        setIsAddGateModalOpen(false);
      } else {
        message.error("Failed to add gates.");
      }
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  // const [selectedType, setSelectedType] = useState(null);

  const handleMenuClick = (e) => {
    setSelectedType(e.key);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      {options.map((option) => (
        <Menu.Item key={option.label}>{option.label}</Menu.Item>
      ))}
    </Menu>
  );

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleAddMannualPopulatedGates = async () => {
    const Data = {
      advertId: advertIds,
      category: selectedCategory,
      type: selectedType,
      values: ["yes", "no"],
      name: name,
    };

    console.log(selectedType, "TEST SELECTED TYPE");

    try {
      const response = await API_MANAGER.addMannualPopulatedGates(Data);
      console.log(response, "TEST RESPONSE");

      if (response?.status === 201) {
        message.success("Gates added successfully.");
        setIsAddGateModalOpen(false);
      } else {
        message.error("Failed to add gates.");
      }
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  const handelSaveGate = () => {
    if (activeTab === "auto") {
      handleAddAutoPopulatedGates();
    } else if (activeTab === "manual") {
      handleAddMannualPopulatedGates();
    }
  };

  const handelassign = () => {
    setTransferModal(true);
  };

  const getHeaderCellProps = (column) => ({
    onClick: (event) => {
      const sortIcon = event.currentTarget.querySelector(
        ".ant-table-column-sorters"
      );

      if (sortIcon && !sortIcon.contains(event.target)) {
        event.stopPropagation();
      }
    },
  });

  const statusIcons = {
    Yes: <img src={YesIcon} alt="Yes" width="20" height="20" />,
    No: <img src={NoIcon} alt="No" width="20" height="20" />,
    "N/A": <img src={NAIcon} alt="N/A" width="20" height="20" />,
  };

  const generateColumns = (columngatedata) => {
    // console.log(gateData, "TEST HIDE COL");
    if (!columngatedata || Object.keys(columngatedata).length === 0) return [];

    let columns = [];

    Object.keys(columngatedata).forEach((key) => {
      const columnTitle = key.replace(/_/g, " ").toUpperCase();

      // Skip hidden column & excluded columns
      if (
        hidecolumn.includes(key) ||
        ["EMAIL", "PHOTO", "DOB", "GENDER", "ADDRESS"].includes(columnTitle)
      )
        return;

      if (key === "details") {
        columns.unshift({
          title: (
            <div style={{ textAlign: "center", marginLeft: "3rem" }}>
              DETAILS
            </div>
          ),
          dataIndex: key,
          key,
          fixed: "left",
          sorter: (a, b) => a[key]?.localeCompare(b[key]),
          showSorterTooltip: false,
          width: 220,
          render: (text, record) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "12px",
                padding: "12px",
                borderRadius: "8px",
                backgroundColor: "#F8FAFC",
                border: "1px solid #E2E8F0",
                minWidth: "200px",
              }}
            >
              <div>
                <div
                  style={{
                    fontWeight: 600,
                    color: "#1E40AF",
                    fontSize: "14px",
                  }}
                >
                  {record?.userName}
                  {console.log(record?.userName, "RRRR")}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "#64748B",
                    marginTop: "2px",
                  }}
                >
                  Start Date: {record.startDate}
                </div>
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <EyeOutlined
                  style={{
                    color: "#3B82F6",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
                <UserProfileCard record={record} />
              </div>
            </div>
          ),
          onHeaderCell: getHeaderCellProps,
        });
      } else {
        columns.push({
          title: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {columnTitle}
            </div>
          ),
          dataIndex: key,
          key,
          align: "center",
          sorter: (a, b) =>
            typeof a[key] === "string" ? a[key].localeCompare(b[key]) : 0,
          showSorterTooltip: false,
          minWidth: 120,
          render: (value) => {
            if (["email", "dob", "gender", "address"].includes(key)) {
              return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {value ? (
                    <img src={YesIcon} alt="Yes" width="20" height="20" />
                  ) : (
                    <img src={NoIcon} alt="No" width="20" height="20" />
                  )}
                </div>
              );
            }

            const icon = statusIcons[value];
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {icon ? <span>{icon}</span> : <span>{value}</span>}
              </div>
            );
          },
          onHeaderCell: getHeaderCellProps,
        });
      }
    });

    return columns;
  };

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (columngatedata) {
      setColumns(generateColumns(columngatedata));
    }
  }, [columngatedata, hidecolumn]);

  const shouldScroll = columns.length > 6;

  const SortableColumn = ({ title, columnKey }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: columnKey });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      display: "flex",
      alignItems: "center",
      gap: 8,
      cursor: "grab",
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <MenuOutlined />
        <span>{title}</span>
      </div>
    );
  };

  // const [columns, setColumns] = useState(initialColumns);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distaunce: 5 }, // Prevents accidental drags
    })
  );

  const onDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = columns.findIndex((col) => col.key === active.id);
    const newIndex = columns.findIndex((col) => col.key === over.id);

    setColumns(arrayMove(columns, oldIndex, newIndex));
  };

  return (
    <SubLayout page={"Mobilisation Management"} whiteBg showSearch>
      {!search && <FullScreenLoader isLoading={loading} />}
      <div style={{ padding: 20 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "10px",
            padding: "10px",
          }}
        >
          <div style={{ flex: "1 1 auto", minWidth: "250px" }}>
            <h2 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <LeftOutlined />
              Mobilisation Management
            </h2>
            <p style={{ color: "gray", margin: 0 }}>
              Customise the requirements for shortlisted candidates
            </p>
          </div>

          <div>
            <Button
              style={{
                display: "flex",
                width: "162px",
                height: "40px",
                padding: "10px 20px",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                borderRadius: "8px",
                background: "#f9cf66",
                color: "#091647",
                textAlign: "center",
                fontFamily: "Arial",
                fontSize: "16px",
                fontWeight: "700",
                border: "none",
                boxShadow: "none",
                cursor: "pointer",
              }}
              onMouseOver={(e) => (e.target.style.color = "#091647")}
              onMouseOut={(e) => (e.target.style.color = "#091647")}
              onClick={openAddGateModal}
            >
              + Add Gate
            </Button>

            <Modal
              title="New Gate"
              width={830}
              open={isAddGateModalOpen}
              onCancel={closeAddGateModal}
              // bodyStyle={{ height: "auto" }}
              centered
              footer={
                <Row
                  justify="end"
                  style={{ width: "100%", flexWrap: "wrap", gap: "10px" }}
                >
                  <Col xs={24} sm={12} md={6}>
                    <Button
                      onClick={closeAddGateModal}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                        border: "1px solid #122D8E",
                        background: "#FFF",
                        boxShadow: "0px 1px 8px -4px rgba(0, 0, 0, 0.12)",
                        color: "#071135", // Text color
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 700, // Bold
                        lineHeight: "normal",
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Button
                      type="primary"
                      onClick={handelSaveGate}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                        backgroundColor: "#F9CF66",
                        border: "none",
                        color: "#091647", // Text color
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 700, // Bold
                        lineHeight: "normal",
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              }
            >
              {/* Tabs */}
              <div
                style={{
                  display: "flex",
                  gap: "12px",
                  fontWeight: "bold",
                  flexWrap: "wrap",
                  marginBottom: "24px",
                  marginTop: "24px",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span
                    style={{
                      color: activeTab === "auto" ? "#000" : "#A0A0A0",
                      cursor: "pointer",
                      borderBottom:
                        activeTab === "auto" ? "2px solid #000" : "none",
                      paddingBottom: "4px",
                    }}
                    onClick={() => setActiveTab("auto")}
                  >
                    AUTO POPULATED
                  </span>
                  <span
                    style={{
                      marginLeft: "10px",
                      color: activeTab === "manual" ? "#000" : "#A0A0A0",
                      cursor: "pointer",
                      borderBottom:
                        activeTab === "manual" ? "2px solid #000" : "none",
                      paddingBottom: "4px",
                    }}
                    onClick={() => setActiveTab("manual")}
                  >
                    MANUAL CREATION
                  </span>
                </div>
                <div>
                  <Col>
                    <Button
                      onClick={handelassign}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                        border: "1px solid #122D8E",
                        background: "#FFF",
                        boxShadow: "0px 1px 8px -4px rgba(0, 0, 0, 0.12)",
                        color: "#071135", // Text color
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 700, // Bold
                        lineHeight: "normal",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "8px", // Space between text and icon
                      }}
                    >
                      Assign
                      <img
                        src={AssignIcon} // Update path based on your project structure
                        alt="Assign Icon"
                        style={{ width: "20px", height: "20px" }} // Adjust size as needed
                      />
                    </Button>

                    <TransferModal
                      transferModal={transferModal}
                      setTransferModal={setTransferModal}
                      candidateSelectedData={candidateSelectedData}
                      oldAdvertId={oldAdvertId}
                      profileId={profileId}
                      candidateName={candidateName}
                      setReloadState={setReloadState}
                      setfilterDataAdvert={setfilterDataAdvert}
                      transferData={transferData}
                      search={advertSearch}
                      setSearch={setAdvertSearch}
                      setadvertIds={setadvertIds}
                      handleAddMannualPopulatedGates={
                        handleAddMannualPopulatedGates
                      }
                    />
                  </Col>
                </div>
              </div>

              {/* Auto-Populated Content */}
              <>
                {activeTab === "auto" && (
                  <>
                    {/* Tag Selection Section */}
                    {/* Tag Selection Section */}
                    {/* Tag Selection Section */}
                    <div style={{ position: "relative", width: "100%" }}>
                      <Select
                        mode="multiple"
                        suffixIcon={null}
                        style={{
                          width: "100%",
                          border: "none",
                          outline: "none",
                          boxShadow: "none",
                        }}
                        dropdownStyle={{ border: "none" }}
                        value={selectedGates}
                        onChange={(values) => setSelectedGates(values)}
                        notFoundContent={null}
                        tagRender={(props) => {
                          const { label, value, closable } = props;

                          const handleClose = (e) => {
                            e.preventDefault();
                            setSelectedGates((prev) =>
                              prev.filter((item) => item !== value)
                            );
                          };

                          return (
                            <Tag
                              color="#f8d7a7"
                              closable={closable}
                              onClose={handleClose}
                              style={{
                                display: "flex",
                                height: "26px",
                                padding: "4px 12px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "7px",
                                background: "#f8d7a7",
                                color: "#091647",
                                fontFamily: "Arial",
                                fontSize: "14px",
                                fontWeight: "400",
                                borderRadius: "10px",
                                margin: "3px",
                                border: "none",
                              }}
                            >
                              {label}
                            </Tag>
                          );
                        }}
                        className="custom-select"
                      />

                      {/* Show "No Selection" fake tag when empty */}
                      {selectedGates.length === 0 && (
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            left: "10px",
                            color: "#A0A0A0",
                            fontSize: "14px",
                            padding: "4px 10px",
                            borderRadius: "10px",
                            background: "#F0F0F0",
                          }}
                        >
                          No Selection
                        </div>
                      )}
                    </div>

                    {/* Personal Details Section */}
                    {/* Personal Details Section */}
                    <div
                      style={{
                        marginBottom: "24px",
                        marginTop: "24px",
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      <h4
                        style={{
                          width: "730px",
                          height: "14px",
                          color: "#091647",
                          fontFamily: "Arial",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "normal",
                        }}
                      >
                        Personal Details
                      </h4>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "20px",
                          rowGap: "10px",
                          maxWidth: "750px",
                        }}
                      >
                        {[
                          // "Email",
                          // "Photo",
                          "Name",
                          // "DOB",
                          // "Gender",
                          "Ethnicity",
                          "Phone Number",
                          // "Next-of-Kin",
                          // "Address",
                        ].map((item) => (
                          <div key={item} style={{ width: "23%" }}>
                            <Checkbox
                              checked={selectedGates.includes(
                                item.toLowerCase()
                              )} // Convert to lowercase
                              onChange={() =>
                                handleCheckboxChange(item.toLowerCase())
                              } // Convert to lowercase
                              style={{
                                color: "#071135",
                                fontFamily: "Arial",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {item}
                            </Checkbox>
                          </div>
                        ))}
                      </div>

                      {/* Additional Details Section */}
                      <h4
                        style={{
                          width: "730px",
                          height: "14px",
                          color: "#091647",
                          fontFamily: "Arial",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "normal",
                          marginTop: "20px",
                        }}
                      >
                        Additional Details
                      </h4>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "20px",
                          rowGap: "10px",
                          maxWidth: "750px",
                        }}
                      >
                        {[
                          "LSL",
                          "PPE",
                          "Travel",
                          "Police Clearance",
                          "Medical",
                        ].map((item) => (
                          <div key={item} style={{ width: "23%" }}>
                            <Checkbox
                              checked={selectedGates.includes(
                                item.toLowerCase()
                              )} // Convert to lowercase
                              onChange={() =>
                                handleCheckboxChange(item.toLowerCase())
                              }
                              style={{
                                color: "#071135",
                                fontFamily: "Arial",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {item}
                            </Checkbox>
                          </div>
                        ))}
                      </div>
                      <div style={{ marginBottom: "24px", marginTop: "24px" }}>
                        <h4
                          style={{
                            width: "730px",
                            height: "14px",
                            color: "#091647",
                            fontFamily: "Arial",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                          }}
                        >
                          Recruitment
                        </h4>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "20px",
                            rowGap: "10px",
                            maxWidth: "750px",
                          }}
                        >
                          {[
                            "Campaign Name",
                            "Project Code",
                            "Advert Name",
                            "Roster",
                            "Employment Type",
                            "Hard Requirments",
                            "Soft Requirments",
                            "Client Approval",
                          ].map((item) => (
                            <div key={item} style={{ width: "23%" }}>
                              <Checkbox
                                checked={selectedGates.includes(
                                  item.toLowerCase()
                                )}
                                onChange={() =>
                                  handleCheckboxChange(item.toLowerCase())
                                }
                                style={{
                                  color: "#071135",
                                  fontFamily: "Arial",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {item}
                              </Checkbox>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* Recruitment Section */}
                  </>
                )}

                {/* Checkbox UI for Gates */}
              </>

              {/* Manual Creation Content */}
              {activeTab === "manual" && (
                <Row gutter={16} style={{ flexWrap: "wrap" }}>
                  <Col xs={24} md={12}>
                    <label style={{ fontWeight: "bold", display: "block" }}>
                      Name
                    </label>
                    <Input
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{ marginBottom: "16px" }}
                    />

                    <div>
                      <label
                        style={{
                          fontWeight: "bold",
                          display: "block",
                          marginBottom: "8px",
                        }}
                      >
                        Type
                      </label>
                      <div
                        style={{
                          display: "flex",
                          gap: "12px",
                          marginBottom: "16px",
                          // flexWrap: "wrap",
                        }}
                      >
                        {options.map((option) => (
                          <Button
                            type={
                              selectedType === option.label
                                ? "primary"
                                : "default"
                            }
                            onClick={() => handleButtonClick(option.label)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              borderRadius: "8px",
                              padding: "12px",
                              flex: "1 0 auto",
                              minWidth: "120px",
                              textAlign: "center",
                              border:
                                selectedType === option.label
                                  ? "2px solid #122D8E"
                                  : "1px solid #122D8E",
                              backgroundColor:
                                selectedType === option.label
                                  ? "#F7F9FF"
                                  : "transparent",
                              color: "#122D8E",
                            }}
                          >
                            {option.icon} {option.label}
                            {selectedType === option.label && (
                              <EditOutlined style={{ marginLeft: "6px" }} />
                            )}
                          </Button>
                        ))}
                      </div>
                      {popoverVisible && (
                        <div
                          style={{
                            width: "307.5px",
                            height: "182.567px",
                            position: "absolute",
                            left: "20px",
                            // bottom: "-0.167px",
                            zIndex: 9999,
                            // backgroundColor: "white",
                          }}
                        >
                          {" "}
                          {/* Adjust width as needed */}
                          <Calendar
                            onSelect={handleDateClick}
                            dateCellRender={dateCellRender}
                            fullscreen={false}
                            headerRender={() => null}
                            value={tomorrow}
                            disabledDate={(current) =>
                              current.isBefore(tomorrow, "day")
                            }
                          />
                        </div>
                      )}
                      {PopoverVisiblePickList && (
                        <Dropdown
                          open={dropdownVisible}
                          onOpenChange={setDropdownVisible}
                          trigger={["click"]}
                          dropdownRender={(menu) => (
                            <div
                              className="custom-dropdown"
                              style={{
                                padding: 16,
                                width: 350,
                                background: "#fff",
                                borderRadius: 6,
                                boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                              }}
                            >
                              <Typography.Title
                                level={5}
                                style={{ marginBottom: 12 }}
                              >
                                Pick List Values
                              </Typography.Title>

                              {/* Input Number Field */}
                              <InputNumber
                                min={1}
                                max={10}
                                value={numValues}
                                onChange={(value) => {
                                  setNumValues(value);
                                  setValues((prev) => {
                                    const newValues = [...prev.slice(0, value)]; // Keep previous values
                                    while (newValues.length < value)
                                      newValues.push(""); // Fill new empty fields
                                    return newValues;
                                  });
                                }}
                                style={{ width: "100%", marginBottom: 16 }}
                              />

                              {/* Input Fields Grid */}
                              <Row gutter={[12, 12]}>
                                {values.map((val, index) => (
                                  <Col span={12} key={index}>
                                    <Input
                                      placeholder={`Value ${index + 1}`}
                                      value={val}
                                      onChange={(e) =>
                                        handleChangePickLists(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                ))}
                              </Row>

                              {/* Buttons */}
                              <Row
                                justify="space-between"
                                style={{ marginTop: 16 }}
                              >
                                <Button
                                  onClick={() => setDropdownVisible(false)}
                                  style={{
                                    marginRight: "10px",
                                    display: "flex",
                                    padding: "12px 30px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px",
                                    flex: "1 0 0",
                                    borderRadius: "8px",
                                    border: "1px solid #122D8E", // Blue border
                                    color: "#122D8E", // Blue text color
                                    textAlign: "center",
                                    fontFamily: "Arial",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                    background: "transparent", // Transparent background
                                  }}
                                >
                                  Cancel
                                </Button>

                                <Button
                                  type="primary"
                                  style={{
                                    display: "flex",
                                    padding: "12px 30px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px",
                                    flex: "1 0 0",
                                    borderRadius: "8px",
                                    background: "#F9CF66", // Yellow background
                                    borderColor: "#F9CF66",
                                    color: "#071135", // Dark blue text color
                                    textAlign: "center",
                                    fontFamily: "Arial",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                  }}
                                  onClick={() => setDropdownVisible(false)}
                                >
                                  Done
                                </Button>
                              </Row>
                            </div>
                          )}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              cursor: "pointer",
                            }}
                          />
                        </Dropdown>
                      )}
                      {/* {dropdown && <></>} */}
                    </div>

                    <label style={{ fontWeight: "bold", display: "block" }}>
                      Category
                    </label>
                    <Select
                      placeholder="Choose Category"
                      style={{ width: "100%", marginTop: "8px" }}
                      onChange={handleCategoryChange}
                    >
                      <Select.Option value="personal details">
                        Personal Details
                      </Select.Option>
                      <Select.Option value="requirement">
                        Requirements
                      </Select.Option>
                      <Select.Option value="other details">
                        Other Details
                      </Select.Option>
                    </Select>
                  </Col>

                  {/* Preview Section */}
                  <Col
                    xs={24}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "16px",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #ddd",
                        padding: "20px",
                        borderRadius: "12px",
                        background: "#fff",
                        width: "100%",

                        maxWidth: "300px",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        textAlign: "center",
                      }}
                    >
                      <h4 style={{ marginBottom: "12px", color: "#333" }}>
                        PREVIEW
                      </h4>
                      <div
                        style={{
                          border: "1px solid #eee",
                          padding: "12px",
                          borderRadius: "12px",
                          background: "#fff",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
                          width: "100%",
                          // maxWidth: "200px",
                        }}
                      >
                        {/* Label with Icon */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            marginBottom: "8px",
                            background: "#fff",
                            borderRadius: "6px",
                            padding: "6px",
                            border: "1px solid #ddd",
                            fontWeight: "bold",
                          }}
                        >
                          <MenuOutlined
                            style={{ fontSize: "16px", color: "#666" }}
                          />
                          <span style={{ flex: 1 }}>{name}</span>
                        </div>

                        {/* Input with Red Icon Dropdown */}
                        {/* Dynamic Field Rendering */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center", // Centers content horizontally
                            border: "1px solid #ddd",
                            borderRadius: "6px",
                            overflow: "hidden",
                            padding: "6px",
                            width: "100%", // Ensures it takes the full width
                            height: "40px", // Adjust height if needed
                          }}
                        >
                          {selectedType === "Calendar" && (
                            <h2
                              style={{
                                textAlign: "center", // Ensures text is centered
                                margin: 0, // Removes default margin
                              }}
                            >
                              {selectedDate || "YYYY-MM-DD"}
                            </h2>
                          )}

                          {selectedType === "Pick List" && <h2>{values}</h2>}

                          {selectedType === "Yes/No" && (
                            <Select
                              value={YesNoValue}
                              onChange={(value) => setYesNoValue(value)}
                              style={{
                                width: "100%",
                                borderRadius: "8px",
                                background: "#F8FAFC",
                                // padding: "6px 12px",
                              }}
                            >
                              <Select.Option value="YES">
                                <CheckCircleFilled
                                  style={{ color: "green", marginRight: "8px" }}
                                />
                                Yes
                              </Select.Option>

                              <Select.Option value="WIP">
                                <ExclamationCircleFilled
                                  style={{
                                    color: "orange",
                                    marginRight: "8px",
                                  }}
                                />
                                WIP
                              </Select.Option>

                              <Select.Option value="NO">
                                <CloseCircleFilled
                                  style={{ color: "red", marginRight: "8px" }}
                                />
                                No
                              </Select.Option>
                            </Select>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Modal>
          </div>

          {/* Responsive adjustments */}
          <style>
            {`
          @media (max-width: 600px) {
            div {
              flex-direction: column;
              align-items: flex-start;
              text-align: left;
            }
          }
        `}
          </style>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
            marginTop: 40,
          }}
        >
          {/* Left Section */}
          <div>
            <TransferPopover
              transferModal={visibleFilter}
              setTransferModal={setVisibleFilter}
              candidateSelectedData={candidateSelectedData}
              oldAdvertId={oldAdvertId}
              profileId={profileId}
              candidateName={candidateName}
              setReloadState={setReloadState}
              setfilterDataAdvert={setfilterDataAdvert}
              transferData={transferData}
              search={advertSearch}
              setSearch={setAdvertSearch}
              setadvertIds={setadvertIds}
              advertIds={advertIds}
              setfilterDataUser={setfilterDataUser}
              reloadState={reloadState}
              setmobilzationColumn={setmobilzationColumn}
              searchText={searchText}
              sortValue={sortValue}
              setLoadingTable={setLoadingTable}
            />
          </div>

          {/* Right Section */}
          <Space size="middle">
            {/* Search Box */}
            <Input
              prefix={<SearchOutlined style={{ color: "#1638B1" }} />}
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              style={{
                height: 40,
                borderRadius: 6,
                backgroundColor: "#FFFFFF",
                border: "1px solid #E2E8F0",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                color: "#64748B",
                fontSize: "14px",
                fontWeight: "400",
                padding: "0px 12px",
                width: "350px",
                minWidth: "150px",
              }}
            />

            {/* Buttons */}
            <ShowHideColumnsPopover
              setmobilzationColumn={mobilzationColumn}
              columnsvisible={columnsvisible}
              toggleVisibility={toggleVisibility}
              sethidecolumn={sethidecolumn}
            />

            <Popover
              content={contentSort}
              trigger="click"
              placement="bottomLeft"
              open={visibleSort}
              onOpenChange={setvisibleSort}
              overlayStyle={{
                borderRadius: 8,
                boxShadow: "0 4px 10px rgba(0,0,0,0.15)",
              }}
              overlayInnerStyle={{ backgroundColor: "#fff", padding: "0px" }}
            >
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  background: "#F8FAFC",
                  border: "1px solid #E2E8F0",
                  borderRadius: "8px",
                  padding: "20px 12px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#64748B",
                  cursor: "pointer",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "28px",
                    height: "28px",
                    background: "#EEF4FF",
                    borderRadius: "6px",
                  }}
                >
                  <EditOutlined
                    style={{ fontSize: "16px", color: "#1638B1" }}
                  />
                </div>
                Sort
              </Button>
            </Popover>
          </Space>
        </div>

        {loadingTable ? (
          <div className="loaderContainer">
            <img src={LoaderGif} alt="Loading..." />
          </div>
        ) : data.length === 0 || columns.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              color: "#071135",
              fontFamily: "Arial",
              // marginBottom: "8px",
              marginTop: "18%",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            <div className="noDataContainer">
              <img src={NoElement} alt="Loading..." />
            </div>
            <h2>No Data</h2>
          </div>
        ) : (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              items={columns.map((col) => col.key)}
              strategy={horizontalListSortingStrategy}
            >
              {console.log(columns.length, "TESTLEN")}
              <Table
                dataSource={data}
                columns={columns.map((col) => ({
                  ...col,
                  minWidth: col.minWidth || 100,
                  title: (
                    <SortableColumn title={col.title} columnKey={col.key} />
                  ),
                }))}
                rowKey="key"
                pagination={false}
                scroll={{
                  x: columns.length > 6 ? 1900 : "auto",
                  y: 500,
                }}
              />
            </SortableContext>
          </DndContext>
        )}
      </div>
      {data.length != 0 && columns.length != 0 && (
        <CustomPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          total={pagination}
          itemPerPage={5}
        />
      )}
    </SubLayout>
  );
}

export default MobilisationManagement;
